import React from "react";
import BackgroundImage from "gatsby-background-image";
import { useStaticQuery, graphql } from "gatsby";
import IconLightbulb from "../../assets/images/about-us/lightbulb.svg";
import IconTeam from "../../assets/images/about-us/team.svg";
import IconPin from "../../assets/images/about-us/pin.svg";
import IconCertificate from "../../assets/images/about-us/certificates.svg";
import IconGallery from "../../assets/images/about-us/gallery.svg";
import { useTranslation } from "react-i18next";
import { Link } from "gatsby-plugin-react-i18next";

const query = graphql`
  query {
    file(relativePath: { eq: "images/headers/about.png" }) {
      childImageSharp {
        fluid( quality: 100, maxWidth: 1980) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export const TABS = {
  ABOUT_US: 'about-us',
  TEAM: 'team',
  MISSION_AND_VISSION: 'mission-and-vison',
  LOCATION: 'location',
  CERTIFICATION: 'certification',
  OUR_OFFICES: 'our-offices',
}



const HeaderAbout = ({ activeTab, setActiveTab }) => {
  const { t } = useTranslation()
  const data = useStaticQuery(query);
  const imageData = data.file.childImageSharp.fluid;

  const content = [
    {
      image: <IconLightbulb />,
      text: `${t('aboutUs.tabs.aboutUs')}`,
      key: TABS.ABOUT_US
    },
    {
      image: <IconTeam />,
      text: `${t('aboutUs.tabs.team')}`,
      key: TABS.TEAM
    },
    {
      image: <IconPin />,
      text: `${t('aboutUs.tabs.location')}`,
      key: TABS.LOCATION
    },
    {
      image: <IconCertificate />,
      text: `${t('aboutUs.tabs.certification')}`,
      key: TABS.CERTIFICATION
    },
    {
      image: <IconGallery />,
      text: `${t('aboutUs.tabs.ourOffices')}`,
      key: TABS.OUR_OFFICES
    },
  ];

  return (
    <header className="m-headerAbout">
      <BackgroundImage fluid={imageData} Tag="div" className="_wr m-headerAbout__wrapper">
        <div className="_w">
          <div className="_12">
            <div className="m-headerAbout__textContent">
              <h1>{t('aboutUs.headline')}</h1>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="_wr">

        <div className="m-headerAbout__buttons">
          {content.map(({ key, image, text }) => (
            <Link to={`/about-us#${key}`}  className={`m-headerAbout__button ${activeTab === key ? '-active' : ''}`} key={key} onClick={() => setActiveTab(key)}>
              <div className="m-headerAbout__button--image">
                {image}
              </div>
              <p className="m-headerAbout__button--text">{text}</p>
            </Link>
          ))}
        </div>
      </div>
    </header>
  );
};

export default HeaderAbout;
