import React from "react";
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import Logo from '../../assets/images/global/logo.svg';
import FacebookIcon from '../../assets/images/footer/facebook.svg'
import LinkedinIcon from '../../assets/images/footer/linkedin.svg'
import YoutubeIcon from '../../assets/images/footer/youtube.svg'
import InstagramIcon from '../../assets/images/footer/instagram.svg'
import { TABS } from "../headers/header-about";
const content = [
  {
    town: "Baar",
    street: "Rathausstrasse 14",
    zip: "6340 Baar"

  },
  {
    town: "Novi Sad",
    street: "Novosadskog sajma 2",
    zip: "21000 Novi Sad"

  },
  {
    town: "Berlin",
    street: "Technopark 1 Strasse",
    zip: "10115 Berlin"

  },
  {
    town: "Toronto",
    street: "90 Queens Wharf Road Suite 911",
    zip: "66777 Toronto"

  },
];

const socials = [
  {
    url: "https://www.instagram.com/ewa.soft/",
    icon: <InstagramIcon />,
  },
  {
    url: "https://www.linkedin.com/company/ewasoft-doo",
    icon: <LinkedinIcon />,
  },
  {
    url: "https://www.youtube.com/channel/UCt-dL0t6ljb5Lu2lf7CucYA",
    icon: <YoutubeIcon />,
  },
  {
    url: "https://www.facebook.com/ewa.soft/",
    icon: <FacebookIcon />,
  },
];

const Footer = () => {
  const { t } = useTranslation()

  return (
    <footer className="m-footer">
      <div className="_wr">
        <div className="m-footer__content">
          <div className="m-footer__socials">
            <div className="m-footer__socials--logo">
              <Logo />
            </div>
            <div className="m-footer__socials--icons">
              {socials.map(({ url, icon }, i) => (
                <Link to={url} key={i} target="_blank">
                  {icon}
                </Link>
              ))}
            </div>
          </div>
          <div className="m-footer__locations">
            <div className="m-footer__locations--all">
              <Link to={`/about-us#${TABS.LOCATION}`} >
                {t('footer.allLocations')}
                <span className="a-arrow -long"></span>
              </Link>
            </div>
            <div className="m-footer__locations--container">

              {content.map(({ town, street, zip }, i) => (
                <div className="m-footer__item" key={i}>
                  <div>
                    <p className="m-footer__item--title">{town}</p>
                    <p className="m-footer__item--text">{street}</p>
                    <p className="m-footer__item--text">{zip}</p>
                  </div>
                </div>
              ))}

            </div>
          </div>
        </div>
      </div>
      <div className="m-footer__bottom">
        <p className="m-footer__bottom--text">
          &copy;{t('footer.copy')} 2021| <strong>ewasoft</strong> | {t('footer.rights')}
        </p>
        {/* <a className="m-footer__bottom--text" href="/#">
          {t('footer.terms')}
        </a>
        <a className="m-footer__bottom--text" href="/#">
          {t('footer.privacy')}
        </a> */}
      </div>
    </footer>
  );
};

export default Footer;
